import React from 'react';
import { ScheduleContainerProps } from './types';
import { ScheduleLayout } from './schedule.layout';
import { useStore } from '../../context/useStore';

const ScheduleContainer: React.FC<ScheduleContainerProps> = (props) => {
  const { store } = useStore();

  const schedule = React.useMemo(() => {
    return store?.schedule?.schedule || [];
  }, [store?.schedule?.schedule]);

  const layoutProps = { ...props, schedule };

  return <ScheduleLayout {...layoutProps} />;
};

export { ScheduleContainer as Schedule };
