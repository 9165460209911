import React from 'react';
import { HeroLayoutProps } from './types';
import { Box, Text } from '@chakra-ui/react';
import { DotButton } from './hero.layout-dots';
import { NextPrevButton } from './hero.layout-arrows';
import {
  Embla,
  EmblaArrowButtons,
  EmblaContainer,
  EmblaDotButtons,
  EmblaImage,
  EmblaImageCover,
  EmblaSlide,
} from './styles';

export const HeroLayout: React.FC<HeroLayoutProps> = (props) => {
  const {
    emblaRef,
    emblaApi,
    selectedIndex,
    scrollSnaps,
    onDotButtonClick,
    onPrevButtonClick,
    onNextButtonClick,
    prevBtnDisabled,
    nextBtnDisabled,
    slides,
  } = props;

  return (
    <Embla>
      <Box ref={emblaRef}>
        <EmblaContainer>
          {slides.map((_item, index: number) => (
            <EmblaSlide key={index}>
              <EmblaImageCover flexDirection={'column-reverse'}>
                <Box
                  bg={'blackAlpha.400'}
                  backdropFilter={'blur(10px)'}
                  p={{ base: '30px', md: '50px' }}
                  width={'100%'}
                >
                  <Text
                    color={'text-reverse'}
                    fontFamily={'Inter-Bold'}
                    fontSize={{ base: '18px', sm: '25px' }}
                    lineHeight={'1.2'}
                    width={{ base: '100%', md: '60%' }}
                  >
                    {_item.title}
                  </Text>
                  {/*<br />*/}
                  {/*<Button>Регистрация</Button>*/}
                </Box>
              </EmblaImageCover>
              <EmblaImage
                loading={'lazy'}
                className="embla__slide__img"
                src={_item.image}
                alt="Your alt text"
              />
            </EmblaSlide>
          ))}
        </EmblaContainer>
      </Box>

      <EmblaArrowButtons display={{ base: 'none', md: 'flex' }}>
        <NextPrevButton
          onClick={onPrevButtonClick}
          disabled={prevBtnDisabled}
          icon={'left'}
        />
        <NextPrevButton
          onClick={onNextButtonClick}
          disabled={nextBtnDisabled}
          icon={'right'}
        />
      </EmblaArrowButtons>

      <EmblaDotButtons>
        {scrollSnaps.map((_, index) => (
          <DotButton
            key={index}
            onClick={() => onDotButtonClick(index)}
            bg={index === selectedIndex ? 'whiteAlpha.900' : 'whiteAlpha.400'}
          />
        ))}
      </EmblaDotButtons>
    </Embla>
  );
};
