import React from 'react';
import { CardsLayoutProps } from './types';
import { Box, Flex, Text } from '@chakra-ui/react';
import { CardLayout } from './speaker_cards.layout-card';
import { StyledContainer } from '../../styles/container';
import { useStore } from '../../context/useStore';

export const CardsLayout: React.FC<CardsLayoutProps> = (props) => {
  const { $t } = useStore();
  const { speakers } = props;

  return (
    <Box bg={'gray.100'} id="speakers">
      <StyledContainer py={{ base: '20px', sm: '100px' }}>
        <Text
          pb={{ base: '20px', sm: '70px' }}
          fontSize={{ base: '2xl', sm: '4xl' }}
          color={'blackAlpha.900'}
        >
          {$t('speakers-heading')}
        </Text>
        <Flex
          w={'100%'}
          direction={{ base: 'column', md: 'row' }}
          flexWrap={'wrap'}
          gap={{ base: '20px', md: '40px' }}
        >
          {speakers.map((speaker) => (
            <CardLayout key={speaker.id} speaker={speaker} />
          ))}
        </Flex>
      </StyledContainer>
    </Box>
  );
};
