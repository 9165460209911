import React from 'react';
import { Avatar, Flex, Text } from '@chakra-ui/react';
import { GuestCardLayoutProps } from './types';

export const GuestCardLayout: React.FC<GuestCardLayoutProps> = (props) => {
  const {
    guest: { avatar, fullname, job_position },
  } = props;

  return (
    <Flex
      pt={'40px'}
      pb={'30px'}
      px={'30px'}
      direction="column"
      gap="20px"
      bg={'gray.100'}
      boxShadow={
        '5px -5px 12px rgba(255,255,255,0.5), -5px 5px 12px rgba(94,104,121,0.35)'
      }
      minH="200px"
      w={{ base: '100%', md: '46%', lg: '30%' }}
    >
      <Avatar size={{ base: 'lg', sm: 'xl' }} src={avatar} />
      <Flex gap={'5px'} flexDirection={'column'} color={'blackAlpha.800'}>
        <Text lineHeight={'27px'} fontSize="2xl" fontFamily={'Inter-SemiBold'}>
          {fullname}
        </Text>
        <Text fontSize={'lg'} color={'brand-primary-dark.100'}>
          {job_position}
        </Text>
      </Flex>
    </Flex>
  );
};
