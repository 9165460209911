import { extendTheme } from '@chakra-ui/react';

export const qazpostThemeColors = {
  'bg-main': '#FFF',
  'bg-secondary': '#dde1e7',
  'bg-secondary-light': 'rgba(255,255,255,0.45)',
  'bg-secondary-dark': 'rgba(94,104,121,0.29)',
  'brand-primary': '#1E4AC4',
  'brand-primary-dark.100': '#173FAE',
  'brand-primary-dark.80': 'rgba(23,63,174,0.8)',
  'brand-primary-dark.60': 'rgba(23,63,174,0.6)',
  'brand-primary-dark.40': 'rgba(23,63,174,0.4)',
  'brand-primary-dark.20': 'rgba(23,63,174,0.2)',
  'brand-secondary': '#47FFC8',
  'brand-secondary-light': '#A0FCF6',
  text: '#000000',
  'text-secondary': '#696969',
  'text-reverse': '#FFF',
};
const theme = extendTheme({
  colors: {
    ...qazpostThemeColors,
  },
  fonts: {
    body: 'Inter-Regular, sans-serif',
    heading: 'Inter-Bold, sans-serif',
    bold: 'Inter-SemiBold, sans-serif',
  },
});

// console.log(theme);

export { theme };
