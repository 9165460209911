import React from 'react';
import { PartnersLayoutProps } from './types';
import { StyledContainer } from '../../styles/container';
import { Flex, Image, Text } from '@chakra-ui/react';
import { useStore } from '../../context/useStore';

export const PartnersLayout: React.FC<PartnersLayoutProps> = (props) => {
  const { $t } = useStore();
  const { partners } = props;

  return (
    <StyledContainer py={{ base: '20px', sm: '100px' }}>
      <Text
        pb={{ base: '0', md: '20px' }}
        color={'brand-primary'}
        fontSize={{ base: '2xl', sm: '4xl' }}
      >
        {$t('partners-title')}
      </Text>
      <Flex
        flexWrap={'wrap'}
        my={{ base: '20px', md: '50px' }}
        justify={'center'}
        alignItems={'center'}
      >
        {partners.map((partner) => {
          if (partner.title === 'kmf') {
            return (
              <Image
                p="18px"
                style={{ width: '165px' }}
                key={partner.id}
                alt={partner.title}
                src={partner.image}
              />
            );
          } else {
            return (
              <Image
                p="18px"
                width={{ base: '50%', md: '25%' }}
                height={{ base: '50%', md: '25%' }}
                key={partner.id}
                alt={partner.title}
                src={partner.image}
              />
            );
          }
        })}
      </Flex>
    </StyledContainer>
  );
};
