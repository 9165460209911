import React, { useCallback } from 'react';

import { HeroLayout } from './hero.layout';
import useEmblaCarousel, {
  EmblaCarouselType,
  EmblaOptionsType,
} from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import { useDotButton } from './hero.layout-dots';
import { usePrevNextButtons } from './hero.layout-arrows';
import { HeroWrapper } from './styles';
import { HeroLayoutCallToAction } from './hero.layout-call-to-action';
import { useStore } from '../../context/useStore';
import { HeroContainerProps } from './types';

const HeroContainer: React.FC<HeroContainerProps> = (props) => {
  const { store } = useStore();
  const { handleNavLinkClick } = props;
  const options: EmblaOptionsType = { loop: true };
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [Autoplay()]);

  const onButtonClick = useCallback((emblaApi: EmblaCarouselType) => {
    const { autoplay } = emblaApi.plugins();
    if (!autoplay) return;
    if (autoplay.options.stopOnInteraction !== false) autoplay.stop();
  }, []);

  const slides2 = React.useMemo(() => {
    return store?.banners?.banners || []; // [] | undefined
  }, [store?.banners?.banners]);
  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(
    emblaApi,
    onButtonClick,
  );

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi, onButtonClick);

  const layoutProps = {
    emblaRef,
    emblaApi,
    //
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
    //
    selectedIndex,
    scrollSnaps,
    onDotButtonClick,
    slides: slides2,
    handleNavLinkClick,
  };

  return (
    <>
      <HeroWrapper w={'100%'} pb={'20px'} overflow={'hidden'}>
        <HeroLayoutCallToAction {...layoutProps} />
        <HeroLayout {...layoutProps} />
      </HeroWrapper>
    </>
  );
};

export { HeroContainer as Hero };
