import axios from 'axios';
import { FormProps } from '../components/form';
import { Language } from '../context/useStore';

export const BACKEND_HOST = '/website/api';

export const axiosInstance = axios.create({
  baseURL: BACKEND_HOST,
  params: { locale: 'ru' },
  headers: {
    Authorization:
      'Bearer e1240df5420da343eeb51e4b2493511f057a0a51c859b922f0c842ef2fdbee0e789f6e995406aab3b57bf27ed52549473802230f58a86b762dd74ef0528bbc0467f18d0400b41f8c416b2d197d04387a18c71f02c44cd8424fd195ed49ca9d683e79c054463de630430a41822bd78ab639f61e39b6de008e5bbbbfbeadc46a3a',
  },
});

export const postProfile = (payload: FormProps) => {
  return axiosInstance({
    url: 'profiles',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: { data: payload },
  });
};

export const loadBanners = (lang: Language) => {
  return axiosInstance({
    url: 'banners?populate=*',
    params: { locale: lang },
  });
};

export const loadDictionary = (lang: Language) => {
  return axiosInstance({
    url: 'dictonaries?populate=*&pagination[start]=0&pagination[limit]=100',
    params: { locale: lang },
  });
};

export const loadSpeakers = (lang: Language) => {
  return axiosInstance({
    url: 'speakers?populate=*',
    params: { locale: lang },
  });
};

export const loadSchedule = (lang: Language) => {
  return axiosInstance({
    url: 'schedules?populate=*',
    params: { locale: lang },
  });
};

export const loadPartners = (lang: Language) => {
  return axiosInstance({
    url: 'partners?populate=*',
    params: { locale: lang },
  });
};

export const loadGuests = (lang: Language) => {
  return axiosInstance({
    url: 'guests?populate=*',
    params: { locale: lang },
  });
};
