import React from 'react';
import styled from '@emotion/styled';
import { Box, Flex, Text } from '@chakra-ui/react';

import { ScheduleCardProps } from './types';

export const ScheduleCard: React.FC<ScheduleCardProps> = (props) => {
  const { program } = props;
  const {
    start_time,
    end_time,
    side,
    title,
    job_position,
    name,
    subtitle,
    name2,
    job_position2,
  } = program;

  return (
    <ContainerStyled
      className={side === 'left' ? 'left' : 'right'}
      _hover={{
        cursor: 'pointer',
        _after: {
          // borderColor: 'transparent',
          borderColor: 'brand-primary',

          bg: 'brand-secondary',
          transform: 'translate(-7px,-7px)',
          boxShadow: '3px 3px 0 #1E4AC4',
        },
        '> :first-child': {
          borderRadius: '1px',
          borderColor: 'brand-primary',
          transform: 'translate(-7px,-7px)',
          boxShadow: '7px 7px 0 #1E4AC4',
        },
      }}
      _after={{
        border: 'solid',
        borderColor: 'blackAlpha.900',
        borderWidth: '3px',
        transition: 'border .2s',
      }}
    >
      <Flex
        py={{ base: '15px', sm: '30px' }}
        px={{ base: '20px', sm: '40px' }}
        gap={'5px'}
        borderRadius="10px"
        position={'relative'}
        backgroundColor="white"
        flexDirection={'column'}
        border={'solid'}
        borderWidth={'3px'}
        transition={'.2s'}
        borderColor={'blackAlpha.900'}
      >
        <Text
          fontSize={{ base: 'xl', sm: '4xl' }}
          color={'brand-primary-dark.60'}
        >
          {start_time} {end_time ? '- ' : ''}
          {end_time} &nbsp;
        </Text>
        <Flex flexDirection={'column'} gap={'10px'}>
          <Text
            color={'blackAlpha.800'}
            fontFamily={'Inter-Semibold'}
            fontSize={{ base: 'xl', sm: '3xl' }}
            lineHeight={{ base: '20px', sm: '40px' }}
          >
            {title}
          </Text>
          {subtitle && <Text color={'text-secondary'}>{subtitle}</Text>}
          {(name || job_position) && (
            <Box mt={'12px'}>
              {name && <Text fontSize={{ base: 'md', sm: 'lg' }}>{name}</Text>}
              {job_position && (
                <Text color="brand-primary">{job_position}</Text>
              )}
            </Box>
          )}
          {(name2 || job_position2) && (
            <Box mt={'12px'}>
              {name2 && (
                <Text fontSize={{ base: 'md', sm: 'lg' }}>{name2}</Text>
              )}
              {job_position2 && (
                <Text color="brand-primary">{job_position2}</Text>
              )}
            </Box>
          )}
        </Flex>
      </Flex>
    </ContainerStyled>
  );
};

const ContainerStyled = styled(Box)<{ position?: 'left' | 'right' }>`
  padding: 10px 30px;
  margin-bottom: -90px;
  position: relative;
  background-color: inherit;
  width: 50%;
  border: #3a3a3a;

  &::after {
    content: '';
    position: absolute;
    width: 26px;
    height: 26px;
    right: -15px;
    background-color: white;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
    transition: 0.2s;
  }
  &.left {
    left: 2.5px;
  }

  &.right {
    left: 50%;
  }
  /* Add arrows to the left container (pointing right) */

  /* Fix the circle for containers on the right side */
  &.right::after {
    left: -14.5px;
  }

  @media screen and (max-width: 740px) {
    width: 100%;
    margin-bottom: 0;
    padding-left: 40px;
    padding-right: 0px;
    padding-bottom: 10px;

    &::before {
      left: 60px;
      border: medium solid white;
      border-width: 10px 10px 10px 0;
      border-color: transparent white transparent transparent;
    }

    /* Make sure all circles are at the same spot */
    &.left::after,
    &.right::after {
      left: 0px;
    }

    /* Make all right containers behave like the left ones */
    &.right {
      left: 0%;
    }
  }
`;
