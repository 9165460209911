import React from 'react';
import { Avatar, Box, Flex, Text } from '@chakra-ui/react';
import { CardLayoutProps } from './types';
import styled from '@emotion/styled';

const CardWrapper = styled(Flex)``;

export const CardLayout: React.FC<CardLayoutProps> = (props) => {
  const {
    speaker: { avatar, fullname, position },
  } = props;

  return (
    <CardWrapper
      pt={'40px'}
      pb={'30px'}
      px={'30px'}
      direction="column"
      gap="20px"
      borderRadius={'15px'}
      bg={'whiteAlpha.600'}
      boxShadow={
        '-5px -5px 8px rgba(255,255,255,0.5), 5px 5px 8px rgba(94,104,121,0.16)'
      }
      minH="200px"
      w={{ base: '100%', md: '46%', lg: '30%' }}
    >
      <Avatar size={{ base: 'lg', sm: 'xl' }} src={avatar} />
      <Flex gap={'5px'} flexDirection={'column'} color={'blackAlpha.800'}>
        <Text lineHeight={'27px'} fontSize="2xl" fontFamily={'Inter-SemiBold'}>
          {fullname}
        </Text>
        <Text fontSize={'lg'} color={'brand-primary-dark.100'}>
          {position}
        </Text>
      </Flex>
    </CardWrapper>
  );
};
