import React from 'react';
import styled from '@emotion/styled';
import { ScheduleLayoutProps } from './types';
import { StyledContainer } from '../../styles/container';
import { ScheduleCard } from './schedule.layout-card';
import BG_IMG from '../../assets/QAZPOST Logotype_symbol_blue 1.png';
import { Box, Text } from '@chakra-ui/react';
import { useStore } from '../../context/useStore';

export const ScheduleLayout: React.FC<ScheduleLayoutProps> = (props) => {
  const { schedule } = props;
  const { $t } = useStore();

  return (
    <Box
      id="schedule"
      bg={'gray.100'}
      backgroundRepeat={'repeat'}
      boxShadow={
        'inset 0px 2px 10px rgba(0,0,0,0.1), inset 0 -2px 10px rgba(0,0,0,.1)'
      }
      backgroundPosition={'center '}
      backgroundSize={'42px'}
      backgroundImage={BG_IMG}
    >
      <StyledContainer backdropFilter={'blur(7px)'}>
        <TimelineStyled
          pt={{ base: '40px', sm: '50px' }}
          pb={{ base: '40px', sm: '180px' }}
        >
          <Text
            py={'20px'}
            pl={{ base: '40px', md: '30px' }}
            pb={{ base: '20px', sm: '40px' }}
            color={'blackAlpha.800'}
            fontSize={{ base: '2xl', sm: '4xl' }}
          >
            {$t('schedule-heading')}
          </Text>
          {schedule.map((program, index) => {
            program.side = index % 2 == 0 ? 'left' : 'right';
            const cardProps = { ...props, program };
            return <ScheduleCard key={program.id} {...cardProps} />;
          })}
        </TimelineStyled>
      </StyledContainer>
    </Box>
  );
};

const TimelineStyled = styled(Box)`
  position: relative;
  width: 100%;
  margin: 0 auto;

  &:after {
    content: '';
    position: absolute;
    width: 3px;
    background-color: #3a3a3a;
    top: 0;
    bottom: 0;
    left: 50%;
    //margin-left:x;
  }


  @media screen and (max-width: 740px) {
    /* Place the timelime to the left */
    &::after {
      left: 15px;
    }
`;
