import React from 'react';
import IR from './Inter-Regular.ttf';
import ISB from './Inter-SemiBold.ttf';
import IB from './Inter-Bold.ttf';
import DB from './Druk Text Cyr Bold Italic.otf';
import DH from './Druk Text Cyr Heavy Italic.otf';
import { Global } from '@emotion/react';

export const GlobalFonts: React.FC = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Inter-Regular';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('${IR}') format('truetype');
      }
      @font-face {
        font-family: 'Inter-Regular';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url('${ISB}') format('truetype');
      }
      @font-face {
        font-family: 'Inter-Regular';
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: url('${IB}') format('truetype');
      }
      @font-face {
        font-family: 'Inter-SemiBold';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('${ISB}') format('truetype');
      }
      @font-face {
        font-family: 'Inter-Bold';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('${IB}') format('truetype');
      }
      @font-face {
        font-family: 'Druk-Bold';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('${DB}') format('opentype');
      }
      @font-face {
        font-family: 'Druk-Heavy';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('${DH}') format('opentype');
      }

      `}
  />
);
