import React from 'react';
import { GuestsLayoutProps } from './types';
import { StyledContainer } from '../../styles/container';
import { useStore } from '../../context/useStore';
import { Box, Flex, Text } from '@chakra-ui/react';
import { GuestCardLayout } from './guests.layout-card';

export const GuestsLayout: React.FC<GuestsLayoutProps> = (props) => {
  const { $t } = useStore();
  const { guests } = props;

  return (
    <Box bg={'bg-main'}>
      <StyledContainer py={{ base: '20px', sm: '100px' }}>
        <Text
          pb={{ base: '20px', sm: '70px' }}
          fontSize={{ base: '2xl', sm: '4xl' }}
          color={'blackAlpha.900'}
        >
          {$t('guests-heading')}
        </Text>
        <Flex
          w={'100%'}
          direction={{ base: 'column', md: 'row' }}
          flexWrap={'wrap'}
          gap={{ base: '20px', md: '40px' }}
        >
          {guests.map((guest) => (
            <GuestCardLayout key={guest.id} guest={guest} />
          ))}
        </Flex>
      </StyledContainer>
    </Box>
  );
};
