import React from 'react';
import {
  BACKEND_HOST,
  loadBanners,
  loadDictionary,
  loadGuests,
  loadPartners,
  loadSchedule,
  loadSpeakers,
} from '../services';
import { AxiosPromise, AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { useDisclosure } from '@chakra-ui/react';
import 'dayjs/locale/ru';
import 'dayjs/locale/kk';

interface StoreSlice {
  [key: string]: any;
}
interface Store {
  [key: string]: StoreSlice;
}

interface StoreLoad {
  res: (res: AxiosResponse) => void;
  req: AxiosPromise;
}
interface StoreContextValue {
  store: Store;
  is_store_loading: boolean;
  $t: (key: string) => string;
  changeLang: (lang: Language) => void;
  lang: Language;
}

export type Language = 'ru' | 'kk' | string;
const LanguageKey = 'lang';

const DEFAULT_LANG = localStorage.getItem(LanguageKey) || 'ru';
console.log(localStorage.getItem(LanguageKey));
console.log(DEFAULT_LANG);
dayjs.locale(DEFAULT_LANG);

const StoreContext = React.createContext<StoreContextValue | null>(null);
export const StoreProvider: React.FC<React.PropsWithChildren> = (props) => {
  const [lang, setLang] = React.useState<Language>(DEFAULT_LANG);

  const [store, setStore] = React.useState<Store>({});
  const {
    isOpen: isLoading,
    onOpen: loadingOn,
    onClose: loadingOff,
  } = useDisclosure({ defaultIsOpen: true });

  const setStoreState = React.useCallback(
    (args: { stateKey: keyof Store; state: Store }) => {
      setStore((prevState) => ({
        ...prevState,
        [args.stateKey]: {
          ...args.state,
        },
      }));
    },
    [],
  );

  const requestsTasks = React.useMemo(
    (): StoreLoad[] => [
      {
        req: loadBanners(lang),
        res: (res: AxiosResponse) => {
          const state: {
            banners: { id: string; image: string; title: string }[];
          } = {
            banners: (res.data.data || []).map((item: any) => {
              return {
                id: item.id,
                image: `${BACKEND_HOST}${item.attributes.image.data.attributes.url}`,
                title: item.attributes.title,
              };
            }),
          };
          setStoreState({ stateKey: 'banners', state });
        },
      },
      {
        req: loadDictionary(lang),
        res: (res: AxiosResponse) => {
          const state: {
            dictionary: { [key: string]: string };
          } = {
            dictionary: {},
          };
          res.data.data.map((item: any) => {
            state.dictionary[item?.attributes?.slug] = item.attributes.value;
          });
          setStoreState({ stateKey: 'dictionary', state });
        },
      },
      {
        req: loadSpeakers(lang),
        res: (res: AxiosResponse) => {
          const state: {
            speakers: {
              id: number;
              fullname: string;
              position: string;
              avatar: string;
              order: number;
            }[];
          } = {
            speakers: (res.data.data || [])
              .map((item: any) => {
                return {
                  id: item.id,
                  avatar:
                    `${BACKEND_HOST}${item?.attributes?.avatar?.data?.attributes?.url}` ||
                    '',
                  fullname: item.attributes.Fullname,
                  position: item.attributes.job_position,
                  order: item.attributes.order,
                };
              })
              .sort((a: any, b: any) => a.order - b.order),
          };
          setStoreState({ stateKey: 'speakers', state });
        },
      },
      {
        req: loadGuests(lang),
        res: (res: AxiosResponse) => {
          const state: {
            guests: {
              id: number;
              fullname: string;
              job_position: string;
              avatar: string;
              order: number;
            }[];
          } = {
            guests: (res.data.data || [])
              .map((item: any) => {
                return {
                  id: item.id,
                  avatar:
                    `${BACKEND_HOST}${item?.attributes?.avatar?.data[0]?.attributes?.url}` ||
                    '',
                  fullname: item.attributes.Fullname,
                  job_position: item.attributes.job_position,
                  order: item.attributes.order,
                };
              })
              .sort((a: any, b: any) => a.order - b.order),
          };
          setStoreState({ stateKey: 'guests', state });
        },
      },
      {
        req: loadSchedule(lang),
        res: (res: AxiosResponse) => {
          const state: {
            schedule: {
              id: number;
              title: string;
              time: string;
              job_position?: string;
              name?: string;
              subtitle?: string;
              name2?: string;
              job_position2?: string;
            }[];
          } = {
            schedule: (res.data.data || [])
              .map((item: any) => {
                return {
                  id: item.id,
                  title: item.attributes.title,
                  start_time:
                    item.attributes.start_time &&
                    item.attributes.start_time.slice(0, 5),
                  end_time:
                    item.attributes.end_time &&
                    item.attributes.end_time?.slice(0, 5),
                  job_position: item.attributes?.job_position,
                  name: item.attributes?.name,
                  job_position2: item.attributes?.job_position2,
                  name2: item.attributes?.name2,
                  subtitle: item.attributes?.subtitle,
                };
              })
              .sort((a: any, b: any) => {
                // Преобразование времени в "HH:MM" в количество минут с полуночи
                const minutesA =
                  parseInt(a.start_time.split(':')[0], 10) * 60 +
                  parseInt(a.start_time.split(':')[1], 10);
                const minutesB =
                  parseInt(b.start_time.split(':')[0], 10) * 60 +
                  parseInt(b.start_time.split(':')[1], 10);

                // Сравнение двух времен
                return minutesA - minutesB;
              }),
          };
          setStoreState({ stateKey: 'schedule', state });
        },
      },
      {
        req: loadPartners(lang),
        res: (res) => {
          const state: {
            partners: {
              id: number;
              title: string;
              image: string;
            }[];
          } = {
            partners: (res.data.data || []).map((item: any) => {
              return {
                id: item.id,
                title: item.attributes.title,
                image: `${BACKEND_HOST}${item.attributes.image.data[0].attributes.url}`,
              };
            }),
          };
          setStoreState({ stateKey: 'partners', state });
        },
      },
    ],
    [lang, setStoreState],
  );

  const LoadData = React.useCallback(() => {
    const tasks = requestsTasks.map((task) => task?.req);
    loadingOn();
    Promise.all(tasks)
      .then((tasksResult) => {
        tasksResult.map((result, index) => {
          requestsTasks[parseInt(index.toString())]?.res(result);
        });
        loadingOff();
      })
      .catch((e) => {
        console.log(e);
      });
  }, [loadingOff, loadingOn, requestsTasks]);

  const $t: StoreContextValue['$t'] = React.useCallback(
    (key) => {
      const dictionary = store?.dictionary?.dictionary || {};
      if (dictionary[key] === undefined) {
        return `${key} ❌`;
      }
      return dictionary[key];
    },
    [store?.dictionary?.dictionary],
  );

  String.prototype.toCapitalize = function ({ each } = { each: false }) {
    if (!each) {
      return this.charAt(0).toUpperCase() + this.slice(1);
    }
    return this.split(' ')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const changeLang: StoreContextValue['changeLang'] = (lang) => {
    dayjs.locale(lang);
    localStorage.setItem(LanguageKey, lang);
    setLang(lang);
  };

  React.useEffect(() => {
    LoadData();
  }, [LoadData]);

  const value = {
    store,
    is_store_loading: isLoading,
    $t,
    changeLang,
    lang,
  };
  return (
    <>
      <StoreContext.Provider value={value}>
        {props.children}
      </StoreContext.Provider>
    </>
  );
};

export const useStore = () =>
  React.useContext(StoreContext) as StoreContextValue;
