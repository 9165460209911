import Countdown, { zeroPad } from 'react-countdown';
import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { ArrowForwardIcon, CalendarIcon } from '@chakra-ui/icons';
import { StyledContainer } from '../../styles/container';
import { useStore } from '../../context/useStore';
import Markdown from 'react-markdown';
import dayjs from 'dayjs';
import { ClickProps } from './types';
import eventLogo from '../../assets/лого демо русс.png';
import eventLogo2 from '../../assets/лого демо каз.png';
import marker from '../../assets/small-marker.svg';

export const HeroLayoutCallToAction: React.FC<ClickProps> = (props) => {
  const { $t, lang } = useStore();
  const { handleNavLinkClick } = props;
  const event_data = $t('DEV-event-date');
  const deadline = Date.parse(event_data);
  const renderer: (...args: any[]) => any = ({
    hours,
    minutes,
    seconds,
    completed,
    days,
  }) => {
    const blocks = [
      { time: days, title: 'DAYS' },
      { time: hours, title: 'HOURS' },
      { time: minutes, title: 'MINUTES' },
      { time: seconds, title: 'SECONDS' },
    ];
    if (completed) {
      // Render a completed state
      return '';
    } else {
      // Render a countdown
      return (
        <>
          {/*<Flex flexDirection={'column'} gap={'1px'}>*/}
          {/*  <Text*/}
          {/*    color={'whiteAlpha.900'}*/}
          {/*    fontFamily={'Druk-Heavy'}*/}
          {/*    fontSize={{ base: '3xl', sm: '7xl' }}*/}
          {/*    lineHeight={{ base: '30px', sm: '70px' }}*/}
          {/*  >*/}
          {/*    {$t('event-name')}*/}
          {/*    <Text color={'whiteAlpha.500'} as={'p'}>*/}
          {/*      {$t('city-event-place')}*/}
          {/*    </Text>*/}
          {/*    {dayjs(event_data).format('DD MMM')}*/}
          {/*  </Text>*/}
          {/*</Flex>*/}
          <Flex flexDirection={'row'} gap={{ base: '0px', sm: '20px' }}>
            {blocks.map((block, index) => {
              return (
                <Flex key={index} flexDirection={'column'}>
                  <Text
                    fontSize={{ base: '5xl', sm: '6xl' }}
                    color={'brand-primary'}
                    p={'0'}
                    lineHeight={'60px'}
                    fontFamily={'Druk-Heavy'}
                  >
                    {zeroPad(block.time)}
                    {blocks.length - 1 === index ? '' : ':'}
                  </Text>
                  <Text
                    fontSize={'10px'}
                    color={'whiteAlpha.700'}
                    fontFamily={'Inter-Semibold'}
                  >
                    {block.title}
                  </Text>
                </Flex>
              );
            })}
          </Flex>
        </>
        // <Text
        //   color={'brand-secondary-light'}
        //   fontSize={'60px'}
        //   fontFamily={'Druk-Heavy'}
        // >
        //   {zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
        // </Text>
      );
    }
  };
  return (
    <>
      <StyledContainer
        pt={{ base: '10px', sm: '100px' }}
        pb={{ base: '40px', sm: '100px' }}
      >
        <Image
          pt={{ base: '80px', md: '16px' }}
          pb={{ base: '36px', md: '48px' }}
          w={'100%'}
          alt="hero-logo"
          src={lang === 'ru' ? eventLogo : eventLogo2}
        />

        <Flex
          w={{ base: '100%', sm: '80%' }}
          flexDirection={'column'}
          gap={'10px'}
        >
          <Flex
            gap={'10px'}
            color={'text-reverse'}
            fontSize={{ base: '20px', md: '3xl' }}
            alignItems={'center'}
          >
            <CalendarIcon color={'brand-primary'} />
            <Text fontFamily={'Inter-SemiBold'}>
              {dayjs(event_data)
                .format('DD MMMM YYYY')
                .toCapitalize({ each: true })}{' '}
              - {$t('DEV-event-start')}
            </Text>
          </Flex>
          {!isNaN(deadline) && (
            <Countdown
              date={deadline}
              zeroPadTime={0}
              zeroPadDays={0}
              renderer={renderer}
            />
          )}
          {/*<Box>*/}
          {/*  <Text*/}
          {/*    color={'text-reverse'}*/}
          {/*    fontSize={{ base: '18px', sm: '25px' }}*/}
          {/*    lineHeight={{ base: '25x', sm: '35px' }}*/}
          {/*    sx={{ '> p': { mb: '16px' } }}*/}
          {/*  >*/}
          {/*    <Markdown>{$t('hero_intro_text')}</Markdown>*/}
          {/*  </Text>*/}
          {/*  <Text*/}
          {/*    mt={'20px'}*/}
          {/*    color={'brand-secondary-light'}*/}
          {/*    fontSize={{ base: '18px', sm: '25px' }}*/}
          {/*    lineHeight={{ base: '25x', sm: '35px' }}*/}
          {/*  >*/}
          {/*    {$t('qazpost-quote-text')}*/}
          {/*  </Text>*/}
          {/*</Box>*/}
          <Flex>
            <Image src={marker} alt="" w={'18px'} mr={'8px'} />
            <Text
              fontSize={{ base: 'xs', md: 'sm' }}
              fontFamily={'Inter-SemiBold'}
              color={'brand-primary'}
            >
              <Markdown>{$t('address')}</Markdown>
            </Text>
          </Flex>

          <Flex gap={{ base: '10px', sm: '15px' }}>
            <Button
              borderWidth={'2px'}
              variant="outline"
              size={{ base: 'md', sm: 'lg' }}
              fontFamily={'Inter-SemiBold'}
              color={'text-reverse'}
              _hover={{ bg: 'white', color: 'text' }}
              rightIcon={<CalendarIcon />}
              onClick={(e) => handleNavLinkClick(e, '#schedule')}
            >
              {$t('schedule').toCapitalize()}
            </Button>
            <Button
              bg={'brand-primary'}
              color={'white'}
              size={{ base: 'md', sm: 'lg' }}
              fontFamily={'Inter-SemiBold'}
              _hover={{ bg: 'brand-primary-dark.60' }}
              rightIcon={<ArrowForwardIcon />}
              onClick={(e) => handleNavLinkClick(e, '#registration')}
            >
              {$t('register_button').toCapitalize()}
            </Button>
          </Flex>
        </Flex>
      </StyledContainer>
    </>
  );
};
