import React from 'react';
import { AboutLayoutProps } from './types';
import { StyledContainer } from '../../styles/container';
import { Box, Text } from '@chakra-ui/react';
import { useStore } from '../../context/useStore';
import Markdown from 'react-markdown';
import BG_IMG from '../../assets/css-pattern-by-magicpattern (1).png';

export const AboutLayout: React.FC<AboutLayoutProps> = () => {
  const { $t } = useStore();

  return (
    <Box
      id="about"
      backgroundBlendMode={'multiply'}
      backgroundImage={`url("${BG_IMG}")`}
      backgroundRepeat={'repeat'}
      py={{ base: '40px', sm: '80px' }}
      backgroundSize={{ base: '30%', md: '300px' }}
    >
      <StyledContainer
        bg={'rgba(72,212,255,0.04)'}
        backdropFilter={'blur(5px)'}
        py={{ base: '15px', sm: '50px' }}
        px={{ base: '15px', sm: '80px' }}
      >
        <Text
          mb={'20px'}
          fontSize={{ base: '2xl', sm: '4xl' }}
          color={'brand-primary-dark.100'}
        >
          {$t('about-title')}
        </Text>
        <Text
          fontSize={{ base: 'md', sm: 'xl' }}
          sx={{ '> p': { mb: '16px' } }}
        >
          <Markdown skipHtml>{$t('about-content')}</Markdown>
          <Markdown>{$t('hero_intro_text')}</Markdown>
        </Text>
      </StyledContainer>
    </Box>
  );
};
