import React, { useMemo } from 'react';
import { YoutubeContainerProps, YoutubeLayoutProps } from './types';
import { YoutubeLayout } from './youtube.layout';
import { useStore } from '../../context/useStore';

const YoutubeContainer: React.FC<YoutubeContainerProps> = (props) => {
  const { $t, lang, changeLang } = useStore();

  const [youtubeLanguage, setYoutubeLanguage] = React.useState(lang);

  const setYoutubeLang: YoutubeLayoutProps['setYoutubeLang'] =
    React.useCallback(
      (lang = 'ru') => {
        console.log(lang);
        setYoutubeLanguage(() => {
          if (lang === 'ru' || lang === 'kk') changeLang(lang);
          return lang;
        });
      },
      [changeLang],
    );

  const links = useMemo(() => {
    return {
      kk: $t('DEV-youtube-kk'),
      ru: $t('DEV-youtube-ru'),
      en: $t('DEV-youtube-en'),
    };
  }, [$t]);

  const layoutProps = { ...props, youtubeLanguage, setYoutubeLang, links };

  return <YoutubeLayout {...layoutProps} />;
};

export { YoutubeContainer as Youtube };
