import React from 'react';
import styled from '@emotion/styled';
import { Flex, Spinner } from '@chakra-ui/react';
import { useStore } from '../context/useStore';

const LoadingWrapper = styled(Flex)<{ isLoaded: boolean }>`
  opacity: 1;
  transform: translateY(0);
  ${(props) => {
    if (props.isLoaded)
      return `
        opacity: 0;
    `;
  }}
`;
export const AppLayoutLoading: React.FC = () => {
  const { is_store_loading } = useStore();
  return (
    <>
      <LoadingWrapper
        isLoaded={!is_store_loading}
        position={'fixed'}
        width={'100%'}
        bg={'brand-primary'}
        height={'100vh'}
        alignItems={'center'}
        justifyContent={'center'}
        opacity={'0.2'}
        transition={'.4s'}
        pointerEvents={'none'}
        zIndex={40}
      >
        <Spinner
          thickness="4px"
          speed="0.3s"
          emptyColor="blackAlpha.400"
          color="brand-secondary"
          size="xl"
        />
      </LoadingWrapper>
    </>
  );
};
