import React from 'react';
import { snazzyStyle } from './snazzy-style';
import { GoogleMap, Marker } from '@react-google-maps/api';
import marker from '../../assets/QAZPOST Logotype_symbol_main 1.svg';
import { useStore } from '../../context/useStore';
import { Box, Text } from '@chakra-ui/react';
import { StyledContainer } from '../../styles/container';

const mapStyles = {
  height: '100%',
  width: '100%',
};

const MapContainer: React.FC = () => {
  // const img = `${window.location.origin}${marker}`;
  const { $t } = useStore();
  const img = marker;

  const defaultCenter = {
    lat: parseFloat($t('DEV-Latitude')) || 51.143974,
    lng: parseFloat($t('DEV-Longitude')) || 71.435806,
    z: 13,
  };

  const onLoad = () => {
    const iconSize = new window.google.maps.Size(100, 100);
    return {
      url: img,
      scaledSize: iconSize,
    };
  };

  return (
    // https://yandex.com/maps/-/CDbrNIKI
    <iframe
      title="address"
      src="https://api-maps.yandex.ru/frame/v1/-/CDbrNIKI?"
      width="100%"
      height="100%"
      frameBorder="0"
    />
  );
};

export { MapContainer as Map };
