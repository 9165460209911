import styled from '@emotion/styled';
import { Box, Button, Flex, Image } from '@chakra-ui/react';
import {
  GLOBAL_CONTAINER_WIDTH_SIZE,
  GLOBAL_CONTAINER_XL_WIDTH_SIZE,
} from '../../styles/container';

export const HeroWrapper = styled(Box)`
  //height: 100vh;

  //filter: contrast(170%) brightness(1000%);
  //padding: 10px 0;
  //backdrop-filter: blur(10px);
  background:
    linear-gradient(45deg, #1e8ac4, #c9c9c9, #1e8ac4, #c9c9c9, #1e4ac4) 0 0 /
      1000% no-repeat,
    url(https://grainy-gradients.vercel.app/noise.svg);

  @media (min-width: ${GLOBAL_CONTAINER_WIDTH_SIZE}px) {
    & {
      //background: red;
      -webkit-animation: gradientAnimation 50s ease infinite;
      animation: gradientAnimation 50s ease infinite;
      @-webkit-keyframes gradientAnimation {
        0% {
          background-position:
            0% 30%,
            0 0;
        }
        50% {
          background-position:
            100% 70%,
            0 0;
        }
        100% {
          background-position:
            0% 30%,
            0 0;
        }
      }
      @keyframes gradientAnimation {
        0% {
          background-position:
            0% 30%,
            0 0;
        }
        50% {
          background-position:
            100% 70%,
            0 0;
        }
        100% {
          background-position:
            0% 30%,
            0 0;
        }
      }
      //--slide-size: 96%; /* Breakpoint SM slide covers 50% of the viewport */
    }
  }
`;

export const Embla = styled(Box)`
  //padding: 10px 0px;
  --slide-size: 1200px;
  position: relative;
  @media (max-width: ${GLOBAL_CONTAINER_WIDTH_SIZE}px) {
    & {
      --slide-size: 96%; /* Breakpoint SM slide covers 50% of the viewport */
    }
  }
`;

export const EmblaContainer = styled(Box)`
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
`;

export const EmblaSlide = styled(Box)`
  //border: 1px solid yellow;
  //background: red;
  box-shadow: 0px 30px 150px 10px #173fae;
  overflow: hidden;
  border-radius: 15px;
  flex: 0 0 var(--slide-size);
  min-width: 0;
  margin: 0px 30px;
  @media (min-width: ${GLOBAL_CONTAINER_XL_WIDTH_SIZE}px) {
    & {
      margin: 0px 200px;
    }
  }
  position: relative;
  box-sizing: border-box;
`;

export const EmblaImage = styled(Image)`
  display: block;
  height: 400px;
  width: 100%;
  object-fit: cover;
`;

export const EmblaImageCover = styled(Flex)`
  //background: red;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
`;

export const EmblaArrowButtons = styled(Box)`
  //display: flex;
  justify-content: space-between;
  padding: 0px 50px;
  pointer-events: none;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
`;

export const EmblaArrowButton = styled(Button)<{ icon: string }>`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  padding: 0 !important;
  pointer-events: auto !important;
  opacity: 0.4;
  transition: opacity 0.2s ease;
  &:hover {
    opacity: 1;
  }
`;

export const EmblaDotButtons = styled(Flex)`
  //background: red;
  padding-top: 10px;
  justify-content: center;
  gap: 6px;
  align-items: center;
`;

export const EmblaDotButton = styled(Button)`
  border-radius: 2px;
  padding: 0;
  height: 7px;
`;
