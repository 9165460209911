import React from 'react';
import { Container, ContainerProps } from '@chakra-ui/react';

export const GLOBAL_CONTAINER_WIDTH_SIZE = 1200;
export const GLOBAL_CONTAINER_XL_WIDTH_SIZE = 1800;
export const StyledContainer: React.FC<
  React.PropsWithChildren & ContainerProps
> = (props) => {
  const containerProps = {
    // bg: 'bg-secondary',
    ...props,
    maxW: { base: '100%', lg: `${GLOBAL_CONTAINER_WIDTH_SIZE}px` },
  };
  return <Container {...containerProps}>{props?.children}</Container>;
};
