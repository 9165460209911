import React from 'react';
import {
  BackgroundProps,
  Box,
  Button,
  ChakraProps,
  FilterProps,
  Flex,
  Image,
  Select,
  Text,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { HeaderLayoutProps } from './types';
import logo from '../../assets/QAZPOST Logotype_white + green.png';
import logo2 from '../../assets/QAZPOST-Logotype-blue.png';
import { StyledContainer } from '../../styles/container';
import { LinkItem } from './styles';
import styled from '@emotion/styled';
import { useStore } from '../../context/useStore';
import shareIcon from '../../assets/share.svg';
import shareIconBlack from '../../assets/share-black.svg';

export const HeaderLayout: React.FC<HeaderLayoutProps> = (props) => {
  const { $t, lang } = useStore();
  const {
    links,
    handleNavLinkClick,
    liftOff,
    btnRef,
    onOpen,
    handleLanguageChange,
    handleShare,
  } = props;

  const bgProps = React.useMemo((): ChakraProps => {
    return liftOff
      ? {
          background: 'whiteAlpha.900',
          backdropFilter: `blur(15px)`,
          boxShadow: '2px 2px 15px rgba(0,0,0,0.06)',
        }
      : {};
  }, [liftOff]);

  const wrapperProps: ChakraProps = {
    ...bgProps,
    w: 'full',
    zIndex: 15,
    transition: '.3s',
    position: 'fixed',
  };
  return (
    <Box as={'nav'} {...wrapperProps}>
      <StyledContainer
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex alignItems={'center'}>
          <Image
            alt="logo"
            h="70px"
            w={'auto'}
            src={liftOff ? logo2 : logo}
            ml={'-10px'}
          />
          <Text
            fontFamily={'Inter-Bold'}
            color={liftOff ? 'brand-primary-dark.60' : 'brand-primary'}
            fontSize={20}
            display={{ base: 'none', md: 'flex' }}
          >
            {$t('brand-event-name').toCapitalize()}
            {$t('brand-old-count')}
          </Text>
        </Flex>
        <Box
          flexWrap="wrap"
          alignItems="center"
          alignSelf="stretch"
          display={{ base: 'none', md: 'flex' }}
        >
          {links.map((link, index) => (
            <LinkItem
              _hover={{
                color: liftOff ? 'brand-primary' : '',
                textDecorationLine: 'underline !important',
                textDecorationThickness: '2px !important',
              }}
              key={index}
              href={link.href}
              px={4}
              color={liftOff ? 'text' : 'text-reverse'}
              fontSize={'md'}
              transition={'.2s'}
              onClick={(e) => handleNavLinkClick(e, link.href)}
            >
              {link.label}
            </LinkItem>
          ))}
          <SelectStyled
            w="max-content"
            value={lang}
            ml={4}
            color={liftOff ? 'text' : 'text-reverse'}
            border={'none'}
            _hover={{ border: 'none' }}
            onChange={handleLanguageChange}
          >
            <option value="ru">RU</option>
            <option value="kk">KZ</option>
          </SelectStyled>
          <Image
            alt="share"
            src={liftOff ? shareIconBlack : shareIcon}
            w={'18px'}
            color={'white'}
            onClick={handleShare}
            cursor={'pointer'}
          />
        </Box>
        <Flex gap={'12px'} display={{ base: 'flex', md: 'none' }}>
          <Image
            alt="share"
            src={liftOff ? shareIconBlack : shareIcon}
            w={'18px'}
            color={'white'}
            onClick={handleShare}
            cursor={'pointer'}
          />
          <Flex gap={'20px'}>
            <Button
              _hover={{ backgroundColor: 'transparent' }}
              // borderColor={liftOff ? 'text' : 'text-reverse'}
              size="sm"
              variant="outline"
              ref={btnRef}
              onClick={onOpen}
            >
              <HamburgerIcon color={liftOff ? 'text' : 'text-reverse'} />
            </Button>
          </Flex>
        </Flex>
      </StyledContainer>
    </Box>
  );
};

const SelectStyled = styled(Select)`
  option {
    color: black;
  }
`;
