import React from 'react';
import { SidebarContainerProps } from './types';
import { SidebarLayout } from './sidebar.layout';
import { NavItem } from '../header';
import { useStore } from '../../context/useStore';

const SidebarContainer: React.FC<SidebarContainerProps> = (props) => {
  const { $t, changeLang } = useStore();

  const links: NavItem[] = [
    { href: '#about', label: $t('about-title').toCapitalize() },
    { href: '#registration', label: $t('registration-title').toCapitalize() },
    { href: '#schedule', label: $t('header-schedule').toCapitalize() },
    { href: '#speakers', label: $t('header-speakers').toCapitalize() },
    { href: '#map', label: $t('header-map').toCapitalize() },
  ];

  const handleLanguageChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const language = event.target.value;
    changeLang(language);
  };

  const layoutProps = { ...props, links, handleLanguageChange };

  return <SidebarLayout {...layoutProps} />;
};

export { SidebarContainer as Sidebar };
