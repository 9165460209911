import React from 'react';
import { GuestsContainerProps } from './types';
import { GuestsLayout } from './guests.layout';
import { useStore } from '../../context/useStore';

const GuestsContainer: React.FC<GuestsContainerProps> = (props) => {
  const { store } = useStore();
  const guests = React.useMemo(() => {
    return store?.guests?.guests || [];
  }, [store?.guests?.guests]);

  const layoutProps = { ...props, guests };

  return <GuestsLayout {...layoutProps} />;
};

export { GuestsContainer as Guests };
