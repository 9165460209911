import React from 'react';
import { PartnersContainerProps } from './types';
import { PartnersLayout } from './partners.layout';
import { useStore } from '../../context/useStore';

const PartnersContainer: React.FC<PartnersContainerProps> = (props) => {
  const { store } = useStore();

  const partners = React.useMemo(() => {
    return store?.partners?.partners || [];
  }, [store?.partners?.partners]);

  const layoutProps = { ...props, partners };

  return <PartnersLayout {...layoutProps} />;
};

export { PartnersContainer as Partners };
