import React from 'react';
import { YoutubeLayoutProps } from './types';
import { StyledContainer } from '../../styles/container';
import {
  Box,
  Flex,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { FaYoutube } from 'react-icons/fa';
import { useStore } from '../../context/useStore';
import preview from '../../assets/youtube-preview.jpg';

export const YoutubeLayout: React.FC<YoutubeLayoutProps> = (props) => {
  const { $t } = useStore();
  const { links, youtubeLanguage, setYoutubeLang } = props;
  console.log(links);

  return (
    <StyledContainer py={{ base: '20px', sm: '100px' }}>
      <Text
        pb={{ base: '0', md: '20px' }}
        color={'brand-primary'}
        fontSize={{ base: '2xl', sm: '4xl' }}
      >
        {$t('youtube-title')}
      </Text>
      <Tabs
        index={Object.keys(links).indexOf(youtubeLanguage)}
        variant="soft-rounded"
        colorScheme="gray"
        onChange={(index) => {
          setYoutubeLang(Object.keys(links)[index]);
        }}
      >
        <TabList>
          {Object.keys(links).map((title, id) => (
            <Tab key={id}>{title.toUpperCase()}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {Object.values(links).map((link, id) => {
            console.log(link);
            return (
              <TabPanel key={id}>
                <Flex
                  position={'relative'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Image src={preview} w={'full'} h={'auto'} />
                  <Box
                    borderRadius={'20px'}
                    px={'20px'}
                    cursor={'pointer'}
                    background={'white'}
                    position={'absolute'}
                    fontSize={'9xl'}
                    color={'red'}
                    onClick={() => {
                      window.location.href = link;
                    }}
                  >
                    <FaYoutube />
                  </Box>
                </Flex>
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
    </StyledContainer>
  );
};
