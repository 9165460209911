import React, { useState } from 'react';
import { FormContainerProps, FormLayoutProps, FormProps } from './types';
import { FormLayout } from './form.layout';
import { postProfile } from '../../services';
import { useDisclosure, useToast } from '@chakra-ui/react';
import { useStore } from '../../context/useStore';

const formDataEmptySnap: FormProps = {
  type: 'guest',
  fullname: '',
  company: '',
  position: '',
  email: '',
  phone: '',
  sponsorship: '',
  source: '',
};
const FormContainer: React.FC<FormContainerProps> = () => {
  const {
    isOpen: isLoading,
    onOpen: loadingOn,
    onClose: loadingOff,
  } = useDisclosure({ defaultIsOpen: false });
  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useDisclosure({ defaultIsOpen: false });
  const toast = useToast();
  const { $t } = useStore();

  const [formData, formDataSetter] = useState<FormProps>(formDataEmptySnap);

  const setFormData: FormLayoutProps['setFormData'] = React.useCallback(
    (key, e) =>
      formDataSetter((prevState) => {
        return {
          ...prevState,
          [key]: e.target.value,
        };
      }),
    [],
  );

  const isValid = React.useCallback(
    ({ sponsorship, source, ...restFormData }: FormProps) => {
      const formData = { ...restFormData };
      const checks = Object.values(formData).filter(
        (field) => (field as string).length > 0,
      );
      if (
        Object.values(formData).length === checks.length &&
        (sponsorship || source)
      ) {
        return true;
      }
      loadingOff();
      toast({
        title: $t('form-case-valid'),
        description: $t('form-case-valid-desc'),
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return false;
    },
    [$t, loadingOff, toast],
  );

  const postFormData: FormLayoutProps['postFormData'] = React.useCallback(
    (e) => {
      e.preventDefault();
      loadingOn();
      if (isValid(formData)) {
        postProfile(formData)
          .then((res) => {
            console.log(res.data);
            openModal();
            formDataSetter(formDataEmptySnap);
          })
          .catch((e) => {
            console.log(e);
            toast({
              title: $t('form-case-error'),
              description: $t('form-case-error-desc'),
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
          })
          .finally(() => {
            loadingOff();
          });
      }
    },
    [$t, formData, isValid, loadingOff, loadingOn, openModal, toast],
  );

  const layoutProps = {
    formData,
    setFormData,
    postFormData,
    isLoading,
    isModalOpen,
    closeModal,
  };
  return <FormLayout {...layoutProps} />;
};

export { FormContainer as RegistrationForm };
