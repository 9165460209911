import { FooterLayout } from './footer.layout';
import { FooterContainerProps } from './types';

const FooterContainer: React.FC<FooterContainerProps> = (props) => {
  const layoutProps = { ...props };

  return (
    <>
      <FooterLayout {...layoutProps} />
    </>
  );
};

export { FooterContainer as Footer };
