import React from 'react';
import { AboutContainerProps } from './types';
import { AboutLayout } from './about.layout';

const AboutContainer: React.FC<AboutContainerProps> = (props) => {
  const layoutProps = { ...props };

  return <AboutLayout {...layoutProps} />;
};

export { AboutContainer as About };
