import React, { useCallback, useEffect } from 'react';

import { HeaderLayout } from './header.layout';
import { HeaderContainerProps, NavItem } from './types';
import { useStore } from '../../context/useStore';

const HeaderContainer: React.FC<HeaderContainerProps> = (props) => {
  const [liftOff, setLiftOff] = React.useState(false);
  const { $t, changeLang } = useStore();

  const links: NavItem[] = React.useMemo(
    () => [
      { href: '#about', label: $t('about-title').toCapitalize() },
      { href: '#registration', label: $t('registration-title').toCapitalize() },
      { href: '#speakers', label: $t('header-speakers').toCapitalize() },
      { href: '#schedule', label: $t('header-schedule').toCapitalize() },
      // { href: '#footer', label: $t('header-footer').toCapitalize() },
      // { href: '#map', label: $t('header-map').toCapitalize(), linkProps: {} },
    ],
    [$t],
  );
  const handlerScroll = useCallback((e: Event) => {
    e.stopPropagation();
    const liftOff = window.scrollY > 20;
    setLiftOff(liftOff);
    // console.log(e);
  }, []);

  const handleLanguageChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const language = event.target.value;
    changeLang(language);
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'QazPost Demo Day',
          url: window.location.href,
        });
        // Обработка успешного шейринга
      } catch (error) {
        // Обработка ошибок
      }
    } else {
      // Fallback для браузеров, не поддерживающих Web Share API
      alert(
        'Шейринг не поддерживается в вашем браузере. Пожалуйста, скопируйте URL.',
      );
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handlerScroll);
    return () => {
      window.removeEventListener('scroll', handlerScroll);
    };
  }, [handlerScroll]);

  const layoutProps = {
    ...props,
    links,
    liftOff,
    handleLanguageChange,
    handleShare,
  };

  return (
    <>
      <HeaderLayout {...layoutProps} />
    </>
  );
};

export { HeaderContainer as Header };
