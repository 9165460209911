import React from 'react';
import { ChakraProvider, useDisclosure } from '@chakra-ui/react';
import { theme } from './theme';
import { AppLayout } from './app.layout';
import { GlobalFonts } from '../fonts/global-fonts';
import { LoadScript } from '@react-google-maps/api';
import { StoreProvider } from '../context/useStore';
import { AppLayoutLoading } from './app.layout-loading';

const AppContainer: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef<HTMLButtonElement>(null);

  const handleNavLinkClick = React.useCallback(
    (e: React.MouseEvent, href: string) => {
      e.preventDefault();
      const targetId = href.slice(1); // Получаем id, убрав "#"
      const targetElement = document.getElementById(targetId);
      console.log(targetId, targetElement, targetElement?.offsetTop);
      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop,
          behavior: 'smooth',
        });
      }
    },
    [],
  );

  const layoutProps = { isOpen, onOpen, onClose, btnRef, handleNavLinkClick };

  return (
    <StoreProvider>
      <ChakraProvider theme={theme}>
        <LoadScript googleMapsApiKey="AIzaSyAFXlXze9I3Ve5nMabtA4oBzUhzMPtHcr4">
          <GlobalFonts />
          <AppLayoutLoading />
          <AppLayout {...layoutProps} />
        </LoadScript>
      </ChakraProvider>
    </StoreProvider>
  );
};

export { AppContainer as App };

/* TODO: Chakra & Themes */
/* TODO: Components list
 * Header - B
 * Hero - B
 * Event Information - B
 * Speaker Card - C
 * Speaker Cards - B
 * Event Schedule Card - C
 * Event Schedule - B
 * Exhibition Information - B
 * Registration - B
 * Form Guest - C
 * Form Sponsor - C
 * Map - B
 * Footer - B */

/* TODO: Redux-Toolkit & Redux-Saga */

/* TODO: Internalization */
