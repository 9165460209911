import React from 'react';
import { Header } from '../components/header';
import { Box, Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { qazpostThemeColors } from './theme';
import { Hero } from '../components/hero';
import { Cards } from '../components/card';
import { Schedule } from '../components/schedule/schedule.container';
import { Map } from '../components/map';
import { Footer } from '../components/footer';
import { RegistrationForm } from '../components/form/form.container';
import { Sidebar } from '../components/sidebar';
import { Partners } from '../components/partners';
import { About } from '../components/about';
import { Exhibition } from '../components/exhibition';
import { StyledContainer } from '../styles/container';
import { useStore } from '../context/useStore';
import { Guests } from '../components/guests';
import { Youtube } from '../components/youtube';

interface useDisclosureProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  btnRef: React.RefObject<HTMLButtonElement>;
  handleNavLinkClick: (e: React.MouseEvent, href: string) => void;
}

export const AppLayout: React.FC<useDisclosureProps> = (props) => {
  const { $t } = useStore();
  const { btnRef, isOpen, onClose, onOpen, handleNavLinkClick } = props;
  const sidebarProps = { isOpen, btnRef, onClose };
  const headerProps = { onOpen, btnRef, handleNavLinkClick };
  const heroProps = { handleNavLinkClick };
  const footerProps = { handleNavLinkClick };
  return (
    <>
      <Header {...headerProps} />
      <Hero {...heroProps} />
      <Youtube />
      <About />
      <Cards />
      <Guests />
      <Sidebar {...sidebarProps} />
      <Schedule />
      <Partners />
      <RegistrationForm />
      <Box id={'map'} display={{ base: 'block', md: 'none' }}>
        <StyledContainer>
          <Text color={'blackAlpha.800'} py={'25px'} fontSize={'14px'}>
            {$t('address')}
          </Text>
        </StyledContainer>
        <Box h={'60vh'}>
          <Map />
        </Box>
      </Box>
      <Footer {...footerProps} />
      {/*<ColorPallet />*/}
    </>
  );
};

const B = styled(Box)`
  padding: 10px 20px;
  //border: 1px solid rgba(0, 0, 0, 0.5);
`;
const ColorPallet = () => {
  return (
    <>
      <Flex
        mt={'400px'}
        bg={'gray.200'}
        flexDirection={'column'}
        p={'40px'}
        w={'100%'}
        overflowX={'scroll'}
      >
        THIS IS COLOR PALLET SHOW CASE
        <Flex bg={'gray.500'}>
          {Object.keys(qazpostThemeColors).map((item, index) => (
            <Box key={index} w={'200px'} border={'1px solid black'}>
              {item}
              <B bg={item} />
            </Box>
          ))}
        </Flex>
      </Flex>
    </>
  );
};
