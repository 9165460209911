import React from 'react';
import { SidebarLayoutProps } from './types';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Link,
  Select,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useStore } from '../../context/useStore';

export const SidebarLayout: React.FC<SidebarLayoutProps> = (props) => {
  const { lang } = useStore();
  const { isOpen, btnRef, onClose, links, handleLanguageChange } = props;

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent backgroundColor="rgba(0, 0, 0, 0.8)">
          <DrawerCloseButtonStyled />
          <br />
          <DrawerHeader></DrawerHeader>

          <DrawerBody backdropFilter={'blur(20px)'}>
            <Flex direction="column">
              <SelectStyled
                w="max-content"
                value={lang}
                color={'text-reverse'}
                border={'none'}
                _hover={{ border: 'none' }}
                onChange={(e) => {
                  onClose();
                  handleLanguageChange(e);
                }}
                fontSize={'md'}
                fontFamily="Inter-Regular"
              >
                <option value="ru">RU</option>
                <option value="kk">KZ</option>
              </SelectStyled>
              {links.map((link, index) => (
                <LinkItem
                  key={index}
                  href={link.href}
                  p={2}
                  color={'text-reverse'}
                  fontSize={'md'}
                  fontFamily="Inter-Regular"
                  transition={'.4s'}
                  onClick={onClose}
                >
                  {link.label}
                </LinkItem>
              ))}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

const DrawerCloseButtonStyled = styled(DrawerCloseButton)`
  path {
    color: white;
  }
`;

const LinkItem = styled(Link)`
  text-decoration: none !important;
`;

const SelectStyled = styled(Select)`
  option {
    color: black;
  }
`;
