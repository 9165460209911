import React from 'react';

import { CardsContainerProps } from './types';
import { CardsLayout } from './speaker_cards.layout';
import { useStore } from '../../context/useStore';

const CardContainer: React.FC<CardsContainerProps> = (props) => {
  const { store } = useStore();
  const speakers = React.useMemo(() => {
    return store?.speakers?.speakers || [];
  }, [store?.speakers?.speakers]);

  const layoutProps = { ...props, speakers };

  return (
    <>
      <CardsLayout {...layoutProps} />
    </>
  );
};

export { CardContainer as Cards };
