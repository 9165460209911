import React from 'react';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Tab,
  Input,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
} from '@chakra-ui/react';
import { CheckIcon, CheckCircleIcon } from '@chakra-ui/icons';
import { FormLayoutProps, FormProps, ModalProps } from './types';
import InputMask from 'react-input-mask';
import styled from '@emotion/styled';
import { Map } from '../../components/map';
import { useStore } from '../../context/useStore';
import { StyledContainer } from '../../styles/container';

export const FormLayout: React.FC<FormLayoutProps> = (props) => {
  const { $t } = useStore();
  const guestFormProps = {
    ...props,
    formData: { ...props.formData, type: 'guest' as FormProps['type'] },
    setFormData: props.setFormData,
    postFormData: props.postFormData,
  };
  const sponsorFormProps = {
    ...props,
    formData: { ...props.formData, type: 'sponsor' as FormProps['type'] },
    setFormData: props.setFormData,
    postFormData: props.postFormData,
  };
  const modalProps = {
    closeModal: props.closeModal,
    isModalOpen: props.isModalOpen,
  };

  return (
    <FormWrapperStyled
      id="registration"
      bgGradient="linear(to-t, brand-primary-dark.20 50%,  transparent 100%)"
      py={{ base: '16px', md: '64px' }}
    >
      <ModalWindow {...modalProps} />
      <StyledContainer>
        <Flex flexDirection={'column'}>
          <Text
            mb={{ base: '12px', md: '36px' }}
            color={'text-reverse'}
            fontSize={{ base: '2xl', md: '4xl' }}
          >
            {$t('registration-title')}
          </Text>
          <Flex
            backgroundColor="transparent"
            direction="row"
            gap={{ base: '0', md: '15px' }}
          >
            <Tabs
              boxShadow={'0 5px 15px rgba(0,0,0,0.3)'}
              bg={'whiteAlpha.800'}
              backdropFilter={'blur(15px)'}
              borderRadius={'10px'}
              w={{ base: '100%', md: '500px' }}
              defaultIndex={0}
              // colorScheme="blue"
              position="relative"
              variant={'filled'}
            >
              <TabList
                justifyContent="left"
                border={'none'}
                borderRadius={'10px 10px 0 0'}
                bg={'white'}
                fontFamily={'Inter-Semibold'}
              >
                <StyledTab p={{ base: '10px 15px', sm: '15px 30px' }}>
                  {$t('registration-guest')}
                </StyledTab>
                <StyledTab p={{ base: '10px 15px', sm: '15px 30px' }}>
                  {$t('registration-sponsor')}
                </StyledTab>
              </TabList>
              <TabIndicator
                height="2px"
                bg="brand-primary"
                borderRadius="1px"
              />
              <TabPanels>
                <StyledTabPanel p={{ base: '15px', sm: '20px 30px' }}>
                  <Form {...guestFormProps} />
                </StyledTabPanel>
                <StyledTabPanel p={{ base: '15px', sm: '20px 30px' }}>
                  <Form {...sponsorFormProps} />
                </StyledTabPanel>
              </TabPanels>
            </Tabs>
            <Flex
              boxShadow={'0 5px 15px rgba(0,0,0,0.3)'}
              flexGrow={1}
              overflow={'hidden'}
              borderRadius={'10px'}
            >
              <Map />
            </Flex>
          </Flex>
        </Flex>
      </StyledContainer>
    </FormWrapperStyled>
  );
};

const StyledTab = styled(Tab)`
  //background: red;
`;

const StyledTabPanel = styled(TabPanel)`
  //background: red;
`;

const ModalWindow: React.FC<ModalProps> = ({ isModalOpen, closeModal }) => {
  const { $t } = useStore();

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={closeModal} isCentered>
        <ModalOverlay
          backdropFilter={{ base: 'blur(15px)', md: 'blur(25px)' }}
          bg={{ base: 'whiteAlpha.50', md: 'whiteAlpha.400' }}
        />
        <ModalContent
          p={5}
          bg={'white'}
          color={'text'}
          boxShadow={'none'}
          borderRadius={'15px'}
          m={'20px'}
        >
          <ModalBody
            display={'flex'}
            justifyContent={'center'}
            flexDirection={'column'}
            gap={'10px'}
          >
            <CheckCircleIcon
              color={'teal.400'}
              fontSize={80}
              mb={'15px'}
            ></CheckCircleIcon>
            <Text fontWeight={'bold'} fontSize={{ base: '2xl', md: '3xl' }}>
              {$t('form-case-success')}
            </Text>
            <Text fontSize={{ base: 'xl', md: '3xl' }}>
              {$t('form-case-success-desc')}
            </Text>
            <Button
              mt={'20px'}
              borderWidth={'2px'}
              colorScheme={'gray'}
              onClick={closeModal}
            >
              {$t('form-case-success-continue').toCapitalize()}
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const Form: React.FC<FormLayoutProps> = ({
  formData,
  setFormData,
  postFormData,
  isLoading,
}) => {
  const { $t } = useStore();
  return (
    <>
      <FormControl display="flex" flexDirection="column">
        <FormStyled onSubmit={postFormData}>
          <FormLabelStyled color={'brand-primary'}>
            {$t('registration-fullname')}
          </FormLabelStyled>
          <FormInputStyled
            placeholder={$t('registration-fullname')}
            variant="filled"
            type="text"
            mb={'15px'}
            disable={isLoading}
            value={formData.fullname}
            onChange={(e) => setFormData('fullname', e)}
          />
          <FormLabelStyled color={'brand-primary'}>
            {$t('registration-company')}
          </FormLabelStyled>
          <FormInputStyled
            placeholder={$t('registration-company')}
            variant="filled"
            type="text"
            value={formData.company}
            onChange={(e) => setFormData('company', e)}
          />
          <FormLabelStyled></FormLabelStyled>
          <FormInputStyled
            placeholder={$t('registration-position')}
            variant="filled"
            type="text"
            mb={'15px'}
            value={formData.position}
            onChange={(e) => setFormData('position', e)}
          />
          <FormLabelStyled color={'brand-primary'}>
            {$t('registration-email')}/{$t('registration-phone')}
          </FormLabelStyled>
          <FormInputStyled
            placeholder={$t('registration-email')}
            variant="filled"
            type="email"
            value={formData.email}
            onChange={(e) => setFormData('email', e)}
          />
          <FormLabelStyled></FormLabelStyled>
          <InputMask
            placeholder={$t('registration-phone')}
            mask="8 (999) 999-99-99"
            value={formData.phone}
            mb={'15px'}
            onChange={(e) => setFormData('phone', e)}
          >
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/*// @ts-ignore*/}
            {(inputProps: any) => (
              <FormInputStyled type="tel" variant="filled" {...inputProps} />
            )}
          </InputMask>
          {formData.type === 'sponsor' ? (
            <>
              <FormLabelStyled color={'brand-primary'}>
                {$t('registration-sponsorship')}
              </FormLabelStyled>
              <Textarea
                variant="filled"
                placeholder={$t('registration-textarea-placeholder')}
                value={formData.sponsorship}
                onChange={(e) => setFormData('sponsorship', e)}
              />
            </>
          ) : (
            <>
              <FormLabelStyled color={'brand-primary'}>
                {$t('registration-source-info')}
              </FormLabelStyled>
              <SelectStyled
                variant="filled"
                placeholder={$t('registration-select-option-placeholder')}
                value={formData.source}
                onChange={(e) => setFormData('source', e)}
              >
                <option value="Instagram">Instagram</option>
                <option value="Facebook">Facebook</option>
                <option value="Знакомые пригласили">
                  {$t('form-option-invite')}
                </option>
                <option value="Другое">{$t('form-option-other')}</option>
              </SelectStyled>
            </>
          )}
          <Button
            mt="18px"
            w="full"
            justifyContent={'space-between'}
            textAlign={'left'}
            bg={'brand-primary'}
            color={'text-reverse'}
            fontFamily={'Inter-SemiBold'}
            _hover={{
              backgroundColor: 'brand-secondary-dark',
            }}
            isLoading={isLoading}
            size={{ base: 'md', sm: 'lg' }}
            type="submit"
            rightIcon={<CheckIcon />}
          >
            {$t('button-submit')}
          </Button>
        </FormStyled>
      </FormControl>
    </>
  );
};

const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormLabelStyled = styled(FormLabel)`
  @media screen and (max-width: 1200px) {
    margin-top: 0;
  }
`;

const FormInputStyled = styled(Input)``;

const SelectStyled = styled(Select)`
  option {
    color: black;
  }
`;

const FormWrapperStyled = styled(Flex)`
  backdrop-filter: blur(10px);
  background:
    linear-gradient(45deg, #1e4ac4, #1b8686, #1e4ac4, #1b8686, #1e4ac4) 0 0 /
      1000% no-repeat,
    url(https://grainy-gradients.vercel.app/noise.svg);
  -webkit-animation: gradientAnimation 50s ease infinite;
  animation: gradientAnimation 50s ease infinite;

  @-webkit-keyframes gradientAnimation {
    0% {
      background-position:
        0% 30%,
        0 0;
    }
    50% {
      background-position:
        100% 70%,
        0 0;
    }
    100% {
      background-position:
        0% 30%,
        0 0;
    }
  }
  @keyframes gradientAnimation {
    0% {
      background-position:
        0% 30%,
        0 0;
    }
    50% {
      background-position:
        100% 70%,
        0 0;
    }
    100% {
      background-position:
        0% 30%,
        0 0;
    }
  }
`;
